import Box from '@mui/material/Box';

const About = () => {
    return (
        <Box sx={{ m: 3 }}>
            <h1>This is the Contact component</h1>
        </Box>
    )
}

export default About;